<div class="page-wrapper" *transloco="let t; scope: 'errorService'; read: 'errorService'">
  <section class="error-wrapper">
    <h2 *ngIf="!title">{{ t("title") }}</h2>
    <h3 *ngIf="!subTitle">
      {{ t("subtitle") }} <br />
      {{ t("copy_message") }}
    </h3>

    <h2 *ngIf="title">{{ title }}</h2>
    <h3 *ngIf="subTitle">{{ subTitle }}</h3>

    <p *ngIf="code">{{ t("error_code") }}: {{ code }}</p>
    <p>{{ message }}</p>

    <div>
      <button (click)="copyToClipboard()" [disabled]="copied">
        <span *ngIf="!copied">{{ t("click_to_copy") }}</span>
        <span *ngIf="copied">{{ t("message_copied") }}</span>
      </button>
      <button (click)="clearError()" class="bg-background_interactive">{{ t("close") }}</button>
    </div>
  </section>
</div>
