import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';
import { ErrorService } from './error.service';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [ErrorService],
  exports: [ErrorComponent],
})
export class ErrorModule {}
