import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheService } from './services/cache/cache.service';
import { HttpClientModule } from '@angular/common/http';
import { ErrorModule } from './shared/error/error.module';

import { RegionService } from './services/region/region.service';
import { SjekkpunktFormComponent } from './sjekkpunkt/sjekkpunkt-form/sjekkpunkt-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytic.service';
import { provideLottieOptions } from 'ngx-lottie';
// Import all the languages you need (you can add any language you want i.e: '../locales/en-GB', '/locales/en-US', ... ).
// In this example, I will use 'es' for Spanish and 'fr' for French:
import myLocaleSv from '@angular/common/locales/sv';
import myLocaleNo from '@angular/common/locales/no';

import { registerLocaleData } from '@angular/common';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

registerLocaleData(myLocaleSv);
registerLocaleData(myLocaleNo);

// AoT requires an exported function for factories

export function initializeApp(translationService: TranslocoService, regionService: RegionService) {
  return () => translationService.setActiveLang(regionService.getClientLanguage());
}

@NgModule({
  declarations: [AppComponent, SjekkpunktFormComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ErrorModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    TranslocoModule,
    TranslocoRootModule,
  ],
  providers: [
    CacheService,
    GoogleAnalyticsService,
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslocoService, RegionService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
