/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { Injectable } from '@angular/core';
import { CountryCode, Product } from 'src/app/models';
import { RegionService } from '../region/region.service';

declare var fbq: Function;
declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  facebookPixelIdMap: Map<CountryCode, string> = new Map<CountryCode, string>();

  constructor(private regionService: RegionService) {
    this.facebookPixelIdMap.set(CountryCode.NO, '1752609402164920');
    this.facebookPixelIdMap.set(CountryCode.SE, '1057896036075085');
  }

  public async getFacebookPixelId(): Promise<string | undefined> {
    return this.facebookPixelIdMap.get(this.regionService.getClientRegion());
  }

  public isEventTypeConsentGranted(eventType?: string): boolean {
    if (window.Cookiebot.consent[eventType!] && window.Cookiebot.consent[eventType!] == true) {
      return true;
    } else {
      return false;
    }
  }

  public async sendMetaPurchaseEvent(product: Product) {
    if (this.isEventTypeConsentGranted('marketing')) {
      return fbq('track', 'Purchase', {
        content_ids: [product.id],
        content_type: 'product',
        value: product.price,
        currency: product.currency,
      });
    } else {
      return false;
    }
  }
}
