import { Deletable } from '../../interface/deletable';
import { Identifiable } from '../../interface/identifiable';
import { HasCountryCode } from '../../interface/has-country-code';
import { Factory } from '../../interface/factory';
import { Currency } from '../../enum/currency';
import { CountryCode } from '../../enum/country-code';
import { ObjectBeneficiaryVersion, UserBeneficiaryVersion } from './template-beneficiary-version';
import { BeneficiaryType } from './beneficiary';
import { Period } from '../..';

export class ProductTemplate implements Deletable, Identifiable, HasCountryCode {
  id: string;
  deleted: boolean;

  beneficiary: BeneficiaryType;
  countryCode: CountryCode;

  // NOTE: Lower index is higher in the hierarchy.
  issuingOrganizationIds: string[];

  parentId?: string;

  currency: Currency;

  priority: number;

  created: Date;
  modified: Date;

  constructor(json: any, beneficiary: BeneficiaryType) {
    this.id = json.id;
    this.deleted = json.deleted ?? false;
    this.beneficiary = beneficiary;

    this.countryCode = json.countryCode as CountryCode;

    this.issuingOrganizationIds = json.issuingOrganizationIds ?? [];

    this.parentId = json.parentId ? json.parentId : undefined;

    this.currency = json.currency as Currency;

    this.priority = json.priority !== undefined ? Number(json.priority) : 0;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<ProductTemplate> {
    return new (class implements Factory<ProductTemplate> {
      make(json: any): ProductTemplate {
        switch (json.beneficiary) {
          case BeneficiaryType.User:
            return new UserProductTemplate(json);
          case BeneficiaryType.Object:
            return new ObjectProductTemplate(json);
          default:
            throw new Error('Unrecognized template beneficiary (' + json.beneficiary + ').');
        }
      }
      getTableName(): string {
        return 'product_templates';
      }
    })();
  }

  static getUrl(countryCode: CountryCode, templateId?: string): string;
  static getUrl(countryCode: CountryCode, templateId: string): string {
    return '/countries/' + countryCode + '/products/templates' + (templateId ? '/' + templateId : '');
  }
}

export class UserProductTemplate extends ProductTemplate {
  versions: UserBeneficiaryVersion[];
  constructor(json: any) {
    super(json, BeneficiaryType.User);
    this.versions = json.versions ? json.versions.map((v: any) => new UserBeneficiaryVersion(v)) : [];
  }

  getLatestVersion(): UserBeneficiaryVersion {
    if (this.versions.length === 0) {
      throw new Error('Product ' + this.id + ' has no versions.');
    }
    return this.versions[this.versions.length - 1];
  }

  hasPeriod(period: Period): boolean {
    const template = this.getLatestVersion();
    for (const cost of template.costs) {
      if (cost.period === Period.Year && period === cost.schedule) {
        return true;
      }
    }
    return false;
  }

  hasUrls(): boolean {
    const template = this.getLatestVersion();

    switch (this.countryCode) {
      case CountryCode.NO:
        return Boolean(template.termsUrl && template.prePurchaseInformationUrl && template.productSheetUrl);
      case CountryCode.SE:
        return Boolean(template.termsUrl && template.prePurchaseInformationUrl && template.productSheetUrl);
      default:
        return false;
    }
  }

  firstSupportedPeriod(): Period | undefined {
    const template = this.getLatestVersion();
    const supportedPeriods = [Period.Year, Period.Month, Period.QuarterYear, Period.HalfYear];
    for (const period of supportedPeriods) {
      for (const cost of template.costs) {
        if (cost.period === period) {
          return period;
        }
      }
    }
    return undefined;
  }

  getPurchasedVersion(version: number): UserBeneficiaryVersion {
    if (version < 1 || version > this.versions.length) {
      throw new Error('Product ' + this.id + ' has no version ' + version + '.');
    }
    return this.versions[version - 1];
  }
}

export class ObjectProductTemplate extends ProductTemplate {
  versions: ObjectBeneficiaryVersion[];

  constructor(json: any) {
    super(json, BeneficiaryType.Object);
    this.versions = json.versions ? json.versions.map((v: any) => new ObjectBeneficiaryVersion(v)) : [];
  }

  getLatestVersion(): ObjectBeneficiaryVersion {
    if (this.versions.length === 0) {
      throw new Error('Product ' + this.id + ' has no versions.');
    }
    return this.versions[this.versions.length - 1];
  }

  

  hasPeriod(period: Period): boolean {
    const template = this.getLatestVersion();
    for (const cost of template.costs) {
      if (cost.period === Period.Year && period === cost.schedule) {
        return true;
      }
    }
    return false;
  }

  hasUrls(): boolean {
    const template = this.getLatestVersion();

    switch (this.countryCode) {
      case CountryCode.NO:
        return Boolean(template.termsUrl && template.prePurchaseInformationUrl && template.productSheetUrl);
      case CountryCode.SE:
        return Boolean(template.termsUrl && template.prePurchaseInformationUrl && template.productSheetUrl);
      default:
        return false;
    }
  }

  firstSupportedPeriod(): Period | undefined {
    const template = this.getLatestVersion();
    const supportedPeriods = [Period.Year, Period.Month, Period.QuarterYear, Period.HalfYear];
    for (const period of supportedPeriods) {
      for (const cost of template.costs) {
        if (cost.period === period) {
          return period;
        }
      }
    }
    return undefined;
  }

  getPurchasedVersion(version: number): ObjectBeneficiaryVersion {
    if (version < 1 || version > this.versions.length) {
      throw new Error('Product ' + this.id + ' has no version ' + version + '.');
    }
    return this.versions[version - 1];
  }
}
