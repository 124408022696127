import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { CountryCode, Currency, LanguageCode } from 'src/app/models';
import { Utilities } from 'src/app/models/class/utilities';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private defaultLanguage: LanguageCode = LanguageCode.Norwegian;
  private defaultRegion: CountryCode = CountryCode.NO;
  private defaultOrganization: Organization = Organization.NO;

  private clientLanguage: LanguageCode;
  private clientRegion: CountryCode;
  private clientOrganization: Organization;

  constructor(
    private translateService: TranslocoService,
    private cache: CacheService,
  ) {
    this.clientLanguage = this.defaultLanguage;
    this.clientRegion = this.defaultRegion;
    this.clientOrganization = this.defaultOrganization;
  }

  async init(): Promise<void> {
    await this.setClientRegion();
    await this.setClientLanguage();
    await this.setClientOrganization();
    this.translateService.setDefaultLang(this.clientLanguage);
    this.translateService.setActiveLang(this.clientLanguage);
    await this.cache.setActiveCountryCode(this.clientRegion);
  }

  async setClientRegion(region?: CountryCode): Promise<void> {
    if (!region) {
      const url: string = window.location.href;
      this.clientRegion = Utilities.getCountryCodeFromUrl(url);
    } else {
      this.clientRegion = region;
    }
  }

  async setClientLanguage(language?: LanguageCode): Promise<void> {
    if (!language) {
      switch (this.clientRegion) {
        // Add for each supported region.
        case CountryCode.SE:
          this.clientLanguage = LanguageCode.Swedish;
          break;
        default:
          this.clientLanguage = LanguageCode.Norwegian;
          break;
      }
    } else {
      this.clientLanguage = language;
    }
  }

  async setClientOrganization(organization?: Organization): Promise<void> {
    if (!organization) {
      switch (this.clientRegion) {
        // Add for each supported region.
        case CountryCode.SE:
          this.clientOrganization = Organization.SE;
          break;
        case CountryCode.FI:
          this.clientOrganization = Organization.FI;
          break;
        case CountryCode.DK:
          this.clientOrganization = Organization.DK;
          break;
        default:
          this.clientOrganization = Organization.NO;
          break;
      }
    } else {
      this.clientOrganization = organization;
    }
  }

  getClientRegion(): CountryCode {
    return this.clientRegion;
  }

  getClientLanguage(): LanguageCode {
    return this.clientLanguage;
  }

  getClientOrganization(): Organization {
    return this.clientOrganization;
  }

  getClientCurrency(): Currency {
    switch (this.clientRegion) {
      case CountryCode.SE:
        return Currency.SEK;
      case CountryCode.FI:
        return Currency.EUR;
      case CountryCode.DK:
        return Currency.DKK;
      case CountryCode.NO:
        return Currency.NOK;
      default:
        return Currency.NOK;
    }
  }

  getDescriptionForRegion(): string {
    switch (this.clientRegion) {
      case CountryCode.SE:
        return 'Webshop för vägassistans av Viking. Viking erbjuder vägassistans och support dygnet runt i Sverige och Europa. Alltid utan självrisk, oavsett antal assistanser.';
      case CountryCode.FI:
        return 'Vikingin tiepalveluiden verkkokauppa. Viking tarjoaa tiepalvelua ja tukea ympäri vuorokauden Suomessa ja Euroopassa. Aina ilman omavastuuta, tukien lukumäärästä riippumatta.';
      case CountryCode.DK:
        return 'Webshop for vejhjælp af Viking. Viking tilbyder vejhjælp og support døgnet rundt i Danmark og Europa. Altid uden selvrisiko, uanset antallet af assistancer.';
      case CountryCode.NO:
        return 'Nettbutikk for veihjelp av Viking. Viking tilbyr veihjelp og støtte hele døgnet i Norge og Europa. Alltid uten egenandel, uavhengig av antall assistanser.';
      default:
        return 'Webshop for roadside assistance by Viking. Viking offers roadside assistance and support around the clock in Norway and Europe. Always without a deductible, regardless of the number of assistances.';
    }
  }
}

export enum Organization {
  NO = 'VIKINGNO',
  SE = 'VIKINGSE',
  FI = 'VIKINGFI',
  DK = 'VIKINGDK',
}
