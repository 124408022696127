import { Injectable } from '@angular/core';
import { CountryCode } from 'src/app/models';
import { RegionService } from '../region/region.service';

// eslint-disable-next-line no-var
declare var fathom: any;

@Injectable({
  providedIn: 'root',
})
export class FathomService {
  fathomIdMap: Map<CountryCode, string> = new Map<CountryCode, string>();

  constructor(private regionService: RegionService) {
    this.fathomIdMap.set(CountryCode.NO, 'IJSSOKQY');
    this.fathomIdMap.set(CountryCode.SE, 'QZAAYBRO');
  }

  public async getFathomId(): Promise<string | undefined> {
    return this.fathomIdMap.get(this.regionService.getClientRegion());
  }
  
  public isFathomLoaded(): boolean {
    return typeof fathom !== 'undefined';
  }

  public trackEvent(eventName: string){
    
    if (!this.isFathomLoaded()) {
      return;
    }
    fathom.trackEvent(`membership_${this.regionService.getClientRegion().toLowerCase()}_fa_${eventName}`);
  }
}
